export enum FEEDBACK_TYPES {
    GET_FEEDBACKS_START = 'GET_FEEDBACKS_START',
    GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS',
    GET_FEEDBACKS_FAILED = 'GET_FEEDBACKS_FAILED',
    REST_FEEDBACKS = 'REST_FEEDBACKS',
    SET_FEEDBACK = 'SET_FEEDBACK',
    SET_FEEDBACK_RATING = 'SET_FEEDBACK_RATING',
    SET_EDIT_FEEDBACK = 'SET_EDIT_FEEDBACK',
    SET_EDITABLE= 'SET_EDITABLE',
    UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
    DELETE_FEEDBACK = 'DELETE_FEEDBACK',
    SET_FEEDBACK_ERROR = 'SET_FEEDBACK_ERROR'
}